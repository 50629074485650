.breadcrumbs {
  @apply flex;

  &__content {
    @apply inline-block;
  }

  &__item {
    @apply flex items-center hover:cursor-pointer;

    &--first {
      @apply inline-flex items-center hover:cursor-pointer;
    }

    &-label {
      @apply text-sm font-medium text-label whitespace-nowrap;

      &--first {
        @apply inline-flex items-center text-sm font-medium text-label whitespace-nowrap;
      }
    }
  }
}
