@import "../../../assets/css/variables.scss";

.searchbar {
  &__wrapper {
    @apply w-430 relative;
  }

  &__input {
    @apply h-9 w-full rounded-4 border-solid border-grey border pl-10 focus:outline-none;
    background-color: $inputbg;
  }
}

.state {
  @apply flex items-baseline w-full min-h-[2.25rem];

  &--error {
    @extend .state;
    @apply mb-2 items-start;

    .state__name {
      @apply text-darkRed;
    }

    .state__field {
      @apply text-darkRed py-1.5 px-2.5 shadow-md-inset-white-015 focus-visible:outline-0 flex-1 min-w-0;
    }
  }

  &--comment {
    @apply flex w-full py-1;
  }

  &--secondary {
    @apply flex;

    .state__name {
      @apply flex-1 text-label text-xs;
      width: 80px;
    }

    .state__value {
      @apply flex-2 text-label text-xs;
    }
  }

  &__name {
    @apply flex-1 max-w-120 text-sm text-darktxt font-semibold;
  }

  &__value {
    @apply flex-1 min-w-[160px] text-sm text-darktxt ml-3;

    &--comment {
      @apply flex-1;
    }
  }

  &__field {
    @apply text-darktxt py-1.5 px-2.5 shadow-md-inset-grey-015 focus-visible:outline-0 flex-1 min-w-0;

    &--warning {
      @extend .state__field;
      @apply shadow-md-inset-white-015;
    }
  }
}

.valuation-field {
  @apply flex items-baseline justify-between text-sm text-darktxt h-60 h-fit border-b border-softPeach;

  label {
    @apply inline-flex whitespace-nowrap items-center font-semibold;
  }

  &__icon {
    @apply flex-2 items-center ml-3;

    span {
      @apply text-secondary font-medium text-10;
    }
  }

  &--request {
    @apply flex items-center text-sm;

    > label {
      @apply inline-flex items-center font-semibold mr-3;
    }

    .btn-request {
      @apply top-2.5 -right-[1px] flex items-center justify-center w-36 h-10 rounded-full bg-secondary font-semibold py-[6px] hover:cursor-pointer text-white;
    }

    .btn-pending {
      @extend .btn-request;
      @apply bg-lightGold text-darkGold;
    }

    .btn-declined {
      @extend .btn-request;
      @apply hover:cursor-default text-darkRed bg-lightRed;
    }
  }
}

.dashboard__content--right {
  .valuation-field {
    @apply p-2;
  }
}
