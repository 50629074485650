@import "../../../assets/css/variables.scss";

.inspections {
  @apply grid grid-cols-2 gap-x-6 gap-y-5 px-5 md:px-0 mb-2.5;

  .inspection {
    @apply flex items-center justify-center flex-col w-32 h-32 rounded-4 shadow-lg hover:cursor-pointer;
    &:active {
      background: #f5f9ff;
      box-shadow: inset 0px 2px 4px rgba(41, 121, 255, 0.15);
    }

    &__icon {
      @apply relative flex items-center justify-center h-16 mb-3;

      &--beta {
        @apply absolute py-1 px-3 -right-8 top-0 rounded-full shadow-md uppercase text-xs font-semibold text-white;
        background-color: $yellow;

        @media (min-width: 1024px) {
          background-color: $label;
        }
      }
    }

    &--title {
      @apply uppercase font-semibold text-xs text-center text-txt;
    }

    &--disabled {
      @extend .inspection;
      box-shadow: inset 0px 2px 4px rgba(41, 121, 255, 0.15);
      background: #f5f9ff;
      @apply hover:cursor-not-allowed;

      .inspection--title {
        @apply text-label;
      }
    }
  }
}

.send-link {
  .link-send {
    @apply px-2 md:px-3;

    &__title {
      @apply text-center text-xl font-bold text-darktxt pb-4 -mt-3;
    }

    &__row {
      @apply grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-7;
    }

    button {
      @apply shadow-md;
    }
  }

  .link-sent {
    @apply flex items-center justify-center py-30 md:py-15;

    &__content {
      @apply flex items-center flex-col max-w-290 md:max-w-375;
    }

    &__title {
      @apply text-xl text-lightGrey font-semibold mt-4;
    }

    &__desc {
      @apply text-center text-base text-lightBlue font-medium mt-4;
    }
  }
}

.manual-search {
  .search-form {
    @apply px-2 md:px-3;

    &__title {
      @apply text-center text-xl font-bold text-darktxt pb-4 -mt-3;
    }

    &__row {
      @apply grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-7;
    }

    &__footer {
      @apply mt-11 w-full align-middle;

      &__tool {
        @apply w-32 my-0 mx-auto;

        button {
          @apply shadow-md;
        }

        &__title {
          @apply uppercase text-lighttxt text-sm font-bold;
        }
      }
    }
  }

  .variant-wrapper {
    @apply max-h-60 overflow-y-scroll flex flex-col mt-1;
    .variant {
      @apply flex items-center pt-3 pb-3 border-b border-b-lightDustBlue hover:cursor-pointer;

      &__logo {
        @apply w-100 rounded-md mr-3;
      }

      &__title {
        @apply text-xs font-medium text-darktxt mb-0 mr-1;
      }

      &__back {
        @apply absolute top-4 left-4;
      }
    }
  }
}

.vehicle-confirm {
  @apply px-5 md:px-10 mt-10;

  &__wrapper {
    @apply grid grid-cols-1 md:grid-cols-2;

    .state {
      @apply items-start mb-2;
    }
  }

  &__item {
    @apply grid grid-cols-2 text-sm text-txt mb-4;

    &__title {
      @apply capitalize font-light;
    }
  }

  &__back {
    @apply absolute top-4 left-4;
  }

  &__footer {
    @apply mt-11 w-full align-middle;

    &__tool {
      @apply w-32 my-0 mx-auto;

      button {
        @apply shadow-md;
      }

      &__title {
        @apply uppercase text-lighttxt text-sm font-bold;
      }
    }
  }
}

.vin-rego {
  &__form {
    @apply px-2 md:px-3;

    &__title {
      @apply text-center text-xl font-bold text-darktxt pb-4 -mt-3;
    }

    &__row {
      @apply grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-7 mt-4;
    }

    .input-label,
    .dropdown-label {
      @apply text-txt;
    }
  }

  &__footer {
    @apply mt-16 md:mt-14 w-32 mx-auto;

    button {
      @apply shadow-md;
    }

    &__title {
      @apply uppercase text-lighttxt text-sm font-bold;
    }
  }
}

.request-valuation {
  @apply flex flex-col items-center px-2 -mt-4;
  &__title {
    @apply text-txt font-semibold text-xl mt-4;
  }

  &__desc {
    @apply w-[220px] text-label text-xs mt-3 text-center;
  }

  &__footer {
    @apply w-[220px] h-10 flex items-center justify-center mt-5 border border-solid border-sidebarbg shadow-md rounded-60 hover:cursor-pointer uppercase font-bold text-sm;
  }
}

.offer {
  @apply w-[310px] -mx-1 md:-mx-6 -mt-4;
}

.internal-valuation {
  @apply w-[310px] -mx-1 md:-mx-6 mt-2;
}

.valuation-log {
  @apply pt-4 pb-3;
  border-bottom: 1px solid #efefef;

  &__info {
    @apply flex items-center justify-between text-primary text-sm font-medium mb-1;

    :not(.status--declined-valuation) span {
      @apply font-normal text-txt;
    }
  }

  &__detail {
    @apply flex items-center justify-between text-primary text-xs font-normal mb-1;

    span {
      @apply opacity-80;
    }
  }
}

.mp-valuation {
  @apply -mx-1 md:-mx-6 w-[310px] -mt-4;
  &__title {
    @apply text-lightGrey text-xl font-semibold;
  }
}

.mp-log {
  @apply flex justify-between items-start pt-4 pb-3 text-lightGrey text-sm font-medium;

  &__info {
    @apply text-sm font-medium mb-1;
  }

  &__detail {
    @apply text-txt font-normal text-right block;

    span {
      @apply text-lightGrey text-xs block;
    }
  }
}

.wholesale {
  @apply w-[305px] mx-2 md:-mx-3 mt-6;

  &__item {
    @apply grid grid-flow-col items-center justify-between mb-9 grid-cols-[4fr,1fr,2.5fr];

     &.with-custom-check {
      @apply grid-cols-none
     }

    label {
      @apply text-lightGrey font-medium text-base leading-5 capitalize;
    }

    .btn-pending {
      @apply flex items-center justify-center rounded-full w-[110px] h-[31px] bg-lightGold text-darkGold font-semibold text-sm hover:cursor-pointer;
    }

    .btn-declined {
      @apply flex items-center justify-center rounded-full w-[110px] h-[31px] text-darkRed bg-lightRed font-semibold text-sm hover:cursor-default;
    }
  }

  &__tool {
    @apply w-[112px] mt-10 mx-auto;

    &__title {
      @apply uppercase text-lighttxt text-sm font-bold;
    }
  }
}

.valuation-add {
  @apply w-[260px] mx-0 md:-mx-5 mt-4 -mb-4;
  &--secondary {
    @extend .valuation-add;

    .valuation-add__txt {
      .input-label {
        @apply text-txt text-sm;
      }
      input {
        @apply pl-3;
      }
    }
  }

  &__desc {
    @apply text-label text-xs font-normal text-center px-2 mb-6 -mt-4;
  }

  &__txt {
    @apply relative;

    input {
      @apply pl-6;
    }

    &__unit {
      @apply absolute left-2.5 top-2;
    }
  }

  &__tool {
    @apply mt-9 w-[162px] mx-auto;

    &__title {
      @apply uppercase text-lighttxt text-sm font-bold;
    }
  }
}

.offer-negotiation {
  @apply w-[310px] mx-0 md:-mx-5 mt-4 -mb-4;

  &__txt {
    @apply relative;

    input {
      @apply pl-6;
    }

    &__unit {
      @apply absolute left-2.5 top-7;
    }
  }

  .input-label,
  .textarea-label {
    @apply text-txt font-medium;
  }

  &__footer {
    @apply flex justify-between mt-8;
  }

  &__tool {
    @apply w-[130px] mx-auto;

    &__title {
      @apply uppercase text-lighttxt text-sm font-bold;
    }

    &--cancel {
      @extend .offer-negotiation__tool;
      button {
        @apply bg-white shadow-md rounded-60 border border-solid border-whiteWhale;

        span {
          @apply uppercase text-darkKnight text-sm font-bold;
        }
      }
    }
  }
}

.offer-add {
  @apply w-[260px] mx-0 md:-mx-5 -mb-4;

  &__title {
    @apply flex items-center flex-col text-txt font-semibold text-xl mb-9 w-full;
  }

  &__desc {
    @apply text-label text-xs font-normal text-center mb-10 px-6;
  }

  &__txt {
    @apply relative;

    input {
      @apply pl-6;
    }

    &__unit {
      @apply absolute left-2.5 top-2;
    }
  }

  &__tool {
    @extend .valuation-add__tool;

    &__title {
      @extend .valuation-add__tool__title;
    }
  }

  &__footer {
    @apply w-[215px] mx-auto mb-3;

    div {
      @apply uppercase text-txt text-sm font-bold py-3 px-4 border border-solid border-sidebarbg shadow-md rounded-60 hover:cursor-pointer;
    }
  }
}

.notification {
  @apply -mx-7 md:-mx-12;

  &__header {
    @apply flex items-center justify-center text-xl font-medium text-primary mb-3;

    &__beta {
      @apply rounded-full flex items-center justify-center bg-yellow text-10 font-semibold text-white w-[45px] h-[26px] ml-2;
    }
  }

  &__content {
    @apply max-h-[500px] w-[375px] overflow-auto;
  }

  &__item {
    @apply bg-lighterBlue px-5 pt-2.5 hover:cursor-pointer;

    &__container {
      @apply border-solid border-lightDustBlue border-b;
    }

    &--read {
      @extend .notification__item;
      @apply bg-white;
    }

    &__title {
      @apply text-primary text-sm font-semibold mb-3;
    }

    &__desc {
      @apply text-primary text-xs font-light mb-3;
    }

    &__date {
      @apply text-dustblue text-10 font-normal pb-3;
    }
  }
}

.confirm {
  @apply w-[238px] -mx-3 -mt-4;

  &__title {
    @apply flex items-center flex-col text-txt font-semibold text-xl mb-5 w-full;
  }

  &__desc {
    @apply text-label text-xs font-normal text-center mb-7;
  }

  &__footer {
    @apply flex items-center justify-between w-full gap-x-2;

    .btn__cancel {
      @apply w-103;

      button {
        @apply bg-white shadow-md;

        span {
          @apply uppercase text-lightGrey text-sm font-bold;
        }
      }
    }

    .btn__confirm {
      @apply w-[126px] ml-2;

      button {
        @apply shadow-md;
        span {
          @apply uppercase text-sm font-bold;
        }
      }
    }
  }
}

.confirm-sale {
  @apply mt-3 w-56 flex flex-col items-center;
}

.sale-details {
  @apply mt-3 -mx-2 lg:mt-0 w-310;

  &__row {
    @apply grid grid-cols-1 gap-y-6;
  }

  &__title,
  .input-label,
  .textarea-label {
    @apply text-txt font-medium text-sm;
  }

  &__control {
    @apply grid grid-cols-1 mt-2;
  }

  &__field {
    @apply w-full flex items-center justify-between border-b border-softPeach py-4;
  }

  &__price {
    @apply relative;

    input {
      @apply pl-6;
    }

    &__unit {
      @apply absolute left-2.5 top-7;
    }
  }

  &__footer {
    @apply w-36 mt-9 flex justify-end ml-auto mr-auto font-bold;

    .btn-confirm {
      span {
        @apply uppercase text-white text-sm font-bold;
      }

      button {
        @apply shadow-md;
      }
    }
  }
}

.vin-location {
  @apply relative w-375 -mx-7 md:-mx-12 px-8 text-sm;
  &__title {
    @apply absolute -top-[34px] right-0 w-full text-primary font-semibold text-lg text-center z-vinLocation;
  }

  &__logo {
    @apply pt-8;
  }

  &__desc {
    @apply text-lightGrey mt-11 pl-4;

    li {
      @apply list-disc mb-2;
    }
  }

  &__footer {
    @apply text-txt mt-8;
    label {
      @apply font-semibold;
    }

    p {
      @apply font-normal mt-2;
    }
  }
}

.plates-location {
  @apply relative w-375 -mx-7 md:-mx-12 px-8 text-sm;
  &__title {
    @apply absolute top-[-34px] right-0 w-full text-primary font-semibold text-lg text-center z-vinLocation;
  }

  &__logo {
    @apply pt-4 pb-8;
  }

  &__desc {
    @apply text-lightGrey pl-4;

    li {
      @apply list-disc mt-8;

      span {
        @apply font-semibold;
      }
    }
  }
}

.complete-inspection {
  @apply relative w-214 text-center text-darktxt;

  h5 {
    @apply text-sm font-bold my-7;
  }

  p {
    @apply text-xs font-medium mb-47;
  }

  &__footer {
    @apply flex flex-col items-center justify-center w-full gap-x-2;

    .btn__cancel {
      @apply w-202 mb-4;

      button {
        @apply bg-white shadow-md;

        span {
          @apply uppercase text-lightGrey text-sm font-bold;
        }
      }
    }

    .btn__confirm {
      @apply w-202 ml-2;

      button {
        @apply shadow-md;
        span {
          @apply uppercase text-sm font-bold;
        }
      }
    }
  }
}

.delete-car {
  @apply relative w-214 h-100 text-center text-black;

  &__title {
    @apply text-sm font-semibold mb-6;
  }

  &__footer {
    @apply flex items-center justify-center w-full gap-x-2;

    .btn__cancel {
      @apply w-9;

      button {
        @apply bg-white shadow-md;

        span {
          @apply uppercase text-lightGrey text-sm font-bold;
        }
      }
    }

    .btn__confirm {
      @apply w-114 ml-2;

      button {
        @apply shadow-md;
        span {
          @apply uppercase text-sm font-bold;
        }
      }
    }
  }
}

.carousel-modal {
  @apply fixed inset-0 z-10 overflow-y-auto;

  &__container {
    @apply relative inline-block my-8 overflow-hidden text-left align-middle transition-all transform shadow-xl;
  }

  &__overlay {
    @apply fixed inset-0 opacity-60;
    background-color: $black;
  }

  .w-responsive {
    @apply w-full h-[370px] md:h-[610px] lg:h-carousel shadow-none;
  }

  .carousel {
    &__close {
      @apply absolute top-2.5 md:top-0 right-[27%] md:right-[13%] z-10 opacity-50 md:opacity-100 bg-white md:bg-transparent w-[20px] md:w-auto h-[20px] md:h-auto rounded-full flex items-center justify-center;
    }
  }
}

.vehicleSwiper {
  height: 100%;
  width: 100%;

  --swiper-navigation-color: white;
  --swiper-pagination-color: white;
}
