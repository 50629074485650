@import "../../../assets/css/variables.scss";

.specification {
  &__content {
    @apply grid grid-cols-1 items-start md:grid-cols-condition xl:grid-cols-specification gap-10 md:gap-6;
  }

  &__tool {
    @apply w-86 mt-10;

    &__txt {
      @apply uppercase text-lighttxt text-sm font-bold;
    }
  }
}

.features {
  &__content {
    @apply grid grid-cols-1 gap-6 text-txt text-sm font-medium;

    .option {
      &__new {
        @apply flex w-[520px] bg-inputbg -mt-2 pr-6;

        .input-wrapper {
          @apply flex-1;
        }

        &__tools {
          @apply flex gap-4 pl-4;
        }
      }

      &__add {
        @apply flex items-center mt-1;
        &--disabled {
          @extend .option__add;

          span {
            @apply text-label ml-2;
          }
        }

        &__title {
          @apply uppercase text-primary font-bold;
        }
      }
    }
  }

  &__tool {
    @extend .specification__tool;
  }
}

.condition {
  &__content {
    @apply grid grid-cols-1	md:grid-cols-condition gap-6;

    .input-label {
      @apply text-sm font-medium text-lightGrey;
    }
  }

  &-title {
    @apply text-lg	text-lightGrey font-bold;

    &--small {
      @apply text-sm font-medium text-lightGrey;
    }
  }

  &__owing__txt {
    @apply relative;

    input {
      @apply pl-6;
    }

    &__unit {
      @apply absolute left-2.5 top-2;
    }
  }

  &__tool {
    @extend .specification__tool;
  }
}

.compliance {
  &__content {
    @apply grid grid-cols-1 items-start md:grid-cols-specification gap-10 md:gap-6;

    &__row {
      @apply grid grid-cols-1 gap-6;
    }
  }

  &-title {
    @apply text-lg	text-lightGrey font-bold;

    &--small {
      @extend .title !optional;
      @apply text-sm font-medium;
    }
  }

  &__tool {
    @extend .specification__tool;
  }
}

.title {
  @apply text-2xl text-txt font-semibold;
}

.summary-details {
  @apply flex items-center text-sm text-label gap-3;
}

.section {
  @apply rounded-xl w-full h-fit p-6 flex flex-col gap-4 shadow-sm;
  background-color: $sidebarbg;

  &--secondary {
    @extend .section;
    @apply px-0 pt-2 shadow-none;

    .state__name {
      @apply font-semibold;
    }

    .state__name {
      @apply font-semibold min-w-144 flex-none;
    }

    .state__value {
      @apply flex-1;
    }

    .state--comment {
      grid-row: 4;
      grid-column: 1 / 3;

      @media (max-width: 746px) {
        grid-row: 18;
        grid-column: 1;
      }
    }
  }

  &__header {
    @apply flex items-center justify-between;

    span {
      @apply font-semibold text-lightGrey text-base;
    }
  }

  &__body {
    @apply w-full;

    &--valuation {
      @apply flex flex-col space-y-4;
    }

    &--header {
      @extend .section__body;
      @apply flex;

      .badge {
        @apply capitalize bg-dustblue text-white rounded-full py-1 px-2.5;
      }

      &.valuation {
        @apply items-start;

        .valuation-field {
          @apply h-8;
        }
      }

      .state__name {
        @apply min-w-144;
        flex: 0;
      }
    }

    &--info {
      @extend .section__body;
      @apply flex flex-col h-full;

      .condition-content {
        @apply grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6;

        &__info {
          @apply flex justify-between mb-1;

          .state__name {
            @apply font-normal;
          }
        }

        &__images {
          @apply flex flex-wrap gap-2.5;

          img {
            @apply w-28 h-28 rounded-10 object-cover;
          }
        }
      }

      .field-comments {
        .valuation-field {
          @apply items-baseline gap-4;
        }
      }
    }

    &--link {
      @apply block text-sm font-light text-txt mt-3 ml-4;
    }

    &__container {
      @apply flex justify-center bg-white rounded-10 shadow-md-inset pt-8 xl:pt-11 overflow-y-auto max-h-400 md:max-h-450 lg:max-h-500;
    }

    &--photos {
      @apply flex justify-items-center flex-wrap gap-3.5 bg-sidebarbg rounded-10 max-h-400 md:max-h-450 lg:max-h-500;

      .photo-wrapper {
        @apply bg-white w-36 box-border border-2 border-lightDustBlue border-solid rounded-lg shadow-photo hover:cursor-pointer;
      }

      .photo-item {
        @apply h-82 flex items-center justify-center;

        &--empty {
          @extend .photo-item;
          @apply text-center;

          span {
            @apply text-waikawaGrey text-10 mt-1;
          }
        }

        &__content {
          @apply relative flex items-center justify-center flex-col h-full overflow-hidden;

          &__delete {
            @apply absolute flex right-3 bottom-3 z-10;
          }

          &__img {
            @apply w-400 h-300 object-cover;
          }
        }
        &--upload {
          @apply hidden;
        }
      }
    }
  }
}

.dashboard__content__footer {
  &__title {
    @apply text-base font-semibold text-txt;
  }

  &__desc {
    @apply text-sm text-label;
  }

  &__tool {
    @apply w-full flex items-center;
  }

  &--additional {
    @apply text-label font-normal text-sm flex flex-col items-start gap-5;

    button {
      @apply text-secondary underline;
    }
  }

  .btn__valuation {
    @apply w-185 ml-2;

    button {
      span {
        @apply uppercase text-sm font-bold;
      }
    }
  }

  .btn__draft {
    @apply w-150 rounded-60;

    button {
      @apply bg-white shadow-md;

      span {
        @apply uppercase text-lightGrey text-sm font-bold;
      }
    }
  }

  .btn__sell__other {
    button {
      @apply bg-white shadow-md;

      span {
        @apply uppercase text-lightGrey text-sm font-bold;
      }
    }

    &.pending__sale {
      button {
        @apply bg-primary shadow-md w-full;
      }

      span {
        @apply text-white;
      }
    }
  }

  .btn__sell__mp {
    @apply w-143;

    button {
      @apply shadow-md;
      span {
        @apply uppercase text-sm font-bold;
      }
    }
  }
}

.direct-offer {
  .btn__direct__offer {
    button {
      @apply shadow-md;

      span {
        @apply uppercase text-sm font-bold;
      }

      &.white {
        @apply bg-white;
  
        span {
          @apply text-lightGrey 
        }
      }
    }
  }
}

.photo-plus {
  @apply relative inline-block text-left;

  &__items {
    @apply absolute right-0 w-250 mt-2 origin-top-right divide-y rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;
    background-color: $white;
  }

  &__item {
    @apply flex rounded-md items-center justify-between w-full px-2 py-2 text-sm text-lightGrey;

    &--capture {
      @apply hidden;
    }
  }
}

.cosmetic-item {
  @apply w-250 mt-4;

  &__cost {
    @apply flex justify-between items-center text-txt font-medium text-xs mb-3;

    &__input {
      @apply flex items-center w-80 text-sm;
    }
  }

  &__slider {
    @apply mb-7;
  }

  &__photos {
    @apply flex flex-wrap gap-2;

    .photo__item {
      @apply relative flex items-center flex-col justify-center w-75 h-75 rounded-10 hover:cursor-pointer;

      &--add {
        @extend .photo__item;
        @apply rounded-4 bg-neutralGrey border border-dashed border-lightDustBlue text-dustblue text-10 font-medium;
      }

      &--delete {
        @apply absolute flex items-center justify-center w-3.5 h-3.5 bg-white rounded-4 right-2 bottom-2 z-10;
      }

      &--upload {
        @apply hidden;
      }
    }
  }
}

.loader-dots div {
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader-dots div:nth-child(1) {
  left: 8px;
  animation: loader-dots1 0.6s infinite;
}
.loader-dots div:nth-child(2) {
  left: 8px;
  animation: loader-dots2 0.6s infinite;
}
.loader-dots div:nth-child(3) {
  left: 32px;
  animation: loader-dots2 0.6s infinite;
}
.loader-dots div:nth-child(4) {
  left: 56px;
  animation: loader-dots3 0.6s infinite;
}
@keyframes loader-dots1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loader-dots3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loader-dots2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
