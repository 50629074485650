@import "../../../assets/css/variables.scss";

.button {
  &-up {
    @apply fixed w-[47.5px] h-[47.5px] rounded-full flex items-center justify-center right-1 md:right-8 bottom-16;
    background-color: $primary;

    &__content {
      @apply flex items-center justify-center;
    }
  }
}

.btn-inspection-new {
  @apply w-110;
  &:focus-visible {
    outline: 0;
  }

  button {
    @apply shadow-inspection;
  }

  &__content {
    @apply flex items-center justify-center py-2.5 lg:py-0;
  }

  &__label {
    @apply ml-2 hidden lg:block text-white text-sm font-bold;
  }
}

.statustab {
  @apply px-2.5 py-0.5 rounded-full;
  background-color: $primary;

  &__label {
    @apply text-white text-sm font-semibold whitespace-nowrap;

    &--link {
      @apply text-primary text-sm font-semibold px-3 py-0.5 whitespace-nowrap;
    }
  }
}

.status {
  @apply rounded-full px-2 py-1 font-semibold text-sm capitalize;

  &--draft,
  &--withdrawn {
    @extend .status;
    @apply text-white bg-dustblue;
  }

  &--review {
    @extend .status;
    @apply text-redVibrant bg-lightRed;
  }

  &--awaiting,
  &--negotiating,
  &--tendered,
  &--link-sent,
  &--scheduled,
  &--pending,
  &--pending-sale,
  &--in-auction,
  &--my-bids-expired,
  &--my-bids {
    @extend .status;
    @apply text-darkGold bg-lightGold;
  }

  &--acquired,
  &--opened,
  &--buy-now {
    @extend .status;
    @apply text-academicBlue bg-brilliantWhite;
  }

  &--withdrawn-buyer {
    @extend .status;
    @apply text-[#9696A0] bg-[#EFEFEF];
  }

  &--won,
  &--accepted,
  &--sold-to-retail,
  &--sold-to-mp,
  &--sold-to-dealership,
  &--active,
  &--sold,
  &--sold-tendered,
  &--sold-direct-offer,
  &--sold-auction {
    @extend .status;
    @apply text-darkGreen bg-lightGreen;
  }

  &--lost,
  &--lost-sale,
  &--rejected,
  &--declined-valuation,
  &--closed,
  &--revoked,
  &--declined {
    @extend .status;
    @apply text-darkRed bg-lightRed;
  }

  &-amount {
    @apply rounded-full px-2 font-semibold text-sm capitalize;
    display: inline-block;
    margin-right: 6px;

    &--draft,
    &--withdrawn {
      @extend .status-amount;
      @apply bg-white text-dustblue;
    }

    &--review {
      @extend .status-amount;
      @apply bg-redVibrant text-lightRed;
    }

    &--awaiting,
    &--negotiating,
    &--tendered,
    &--link-sent,
    &--pending,
    &--my-bids-expired,
    &--my-bids {
      @extend .status-amount;
      @apply bg-darkGold text-lightGold;
    }

    &--lost,
    &--lost-sale,
    &--rejected,
    &--declined,
    &--revoked {
      @extend .status-amount;
      @apply bg-darkRed text-lightRed;
    }

    &--acquired,
    &--opened,
    &--buy-now {
      @extend .status-amount;
      @apply bg-academicBlue text-brilliantWhite;
    }

    &--withdrawn-buyer {
      @extend .status-amount;
      @apply bg-[#9696A0] text-brilliantWhite;
    }

    &--won,
    &--accepted,
    &--sold-to-retail,
    &--sold-to-mp,
    &--sold,
    &--sold-to-dealership,
    &--sold-tendered,
    &--sold-direct-offer,
    &--sold-auction {
      @extend .status-amount;
      @apply bg-darkGreen text-lightGreen;
    }
  }
}

.btn-notification {
  @apply relative w-max;
  &__icon {
    @apply absolute -top-1 -right-1 bg-redVibrant shadow-notification rounded-full w-15 h-15;
  }
}
